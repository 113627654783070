


<template>
  <div class="UserHome">
    <div class="bottomDiv">
      <div class="index_title" style="border-bottom: none">用户消费明细</div>
      <div class="formDiv" style="width:80vw; margin-left: 8vw;">
        <el-form style="width:50vw; margin-left: 5vw;" label-width="60px">
          
              <el-form-item label="手机号:">
                <el-input
                style="width:50vw"
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>

              <el-form-item label="运营商:">
                <el-select style="width:50vw" v-model="FormData.username" placeholder="请选择运营商">
                  <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                </el-select>
              </el-form-item>
          
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                style="height: 40px; width: 100%;"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
          
              <div class="BtnSearch" style="margin-top:5px;" @click="searchBtn()">查询</div>
       
        </el-form>
      </div>
      <div class="tableDiv">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border 
        v-loading="loading"
        height= "480px"
        style="
          width: 90%;
          margin: 20px auto;
          overflow-y: auto;
        "
      >
    
          <el-table-column label="订单号" prop="order_id" width="110"/>
          <el-table-column label="用户手机号" prop="phone" width="110"/>
          <el-table-column label="设备号" prop="device_id"  width="130"/>
          <el-table-column label="站点名称" prop="station_name" width="160" />
          <el-table-column label="充电车型" prop="type_id">
          <template #default="scope">
            <span v-if="scope.row.type_id == 2">电动车</span>
            <span v-if="scope.row.type_id == 4">电动汽车</span>
          </template>
          </el-table-column>
          <el-table-column label="端口号" prop="socket"  width="50" />
          <el-table-column label="充电时长" prop="cost_time" :formatter="rounding" />
          <!-- <el-table-column label="充电状态" prop="status" /> -->
          <el-table-column label="开始时间" prop="start_time"  />
          <el-table-column label="结束时间" prop="end_time" />
          <el-table-column label="停止原因" prop="stop_charge_status" width="200" />
          <el-table-column label="消费金额" prop="cost_money" :formatter="rounding"/>
          <el-table-column label="付款方式" prop="pattern_of_payment" />
          <el-table-column label="所属运营商" prop="username" width="100"/>
        </el-table>
        
      </div>

        </div>
      
    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_consumption_detail,operator_data } from "@/request/api";
import { ElMessage } from "element-plus";

import ProvinceCityCountry from '../../assets/json/address'
export default {
  name: "ExpendDetail",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime:['',''],
        
      },
      isadmin:false,
      users:[],
      tableData: [
       
      ],
      carList:[
        {
          value:2,
          label:'电动车'
        },
        {
          value:4,
          label:'电动汽车'
        }
      ],
      car:'',
      //   分页
      currentPage:1,
      pageSize:20,
      total:400,
      checked:0,
      small:true,

      loading: false,
      provinceData: ProvinceCityCountry.address,
    });
    const rounding=(row,column)=> {
    return parseFloat(row[column.property]?row[column.property]:'0').toFixed(2)
  }
    const formatDate =(row, column) => {
      // 获取单元格数据
      let data = row[column.property]
      if(data == null) {
          return null
      }
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    }
    const searchBtn = () => {
      getList();
    };
   
    const getList = () => {
      const stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : undefined;
      const endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : undefined;
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        username : data.FormData.username,
        phone:data.FormData.phone,
        type_id:data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate
      }
        data.loading = true;
        user_consumption_detail(dataa).then((res) => {
          if (res) {
            console.log(res,'消费明细列表');
            data.loading = false;
            if (res.code == 200) {
              data.tableData = res.data;
              data.total = res.data.length
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
    };
    // const getTime = () => {
    //   var aData = new Date();
    //   const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
    //   data.FormData.orderTime[0] = nowdate
    //   data.FormData.orderTime[1] = ''
    //   console.log(data.FormData.orderTime,'data.order_timedata.order_time');
    // }
    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        console.log(res,'sssssss');
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
      }
      });
    };

    onMounted(() => {
        // getTime()
        getList();
        getUsername();
        
        
    });
    return {
      ...toRefs(data),
      formatDate,
      searchBtn,
      rounding,
      getList,
      // getTime,
      getUsername
    };
  },
};
</script>
<style scoped>

.bottomDiv{
  height: 100%;
  background-color: #fff;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}
.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    margin-top: -10px;
}
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
</style>